import React from 'react';
import PropTypes from 'prop-types';
import './Bullet.css';

const Section = ({ text }) => <li className="section-list-item">{text}</li>;

Section.propTypes = {
  text: PropTypes.string
};

export default Section;
