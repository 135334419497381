import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Bullet from './../Bullet/Bullet.jsx';

import './SectionProduct.css';

// const Section = ({ title, subTitle, description, bullets, image }) => (
const Section = ({ title, subTitle, bullets, image }) => (
  <div className="sectionProduct">
    <div className="sectionProduct-textContainer">
      <h3 className="sectionProduct-subTitle">{subTitle}</h3>
      <h2 className="sectionProduct-title">{title}</h2>
      {/*<p className="sectionProduct-description">{description}</p>*/}
      <ul className="sectionProduct-bullets">
        {bullets.map((bullet, i) => (
          <Bullet key={i} text={bullet} />
        ))}
      </ul>
    </div>
    <div className="sectionProduct-imageContainer">
      <Img fluid={image.node.childImageSharp.fluid} />
    </div>
    <div className="cl"></div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  bullets: PropTypes.array,
  image: PropTypes.object
  // className: PropTypes.string
};

export default Section;
