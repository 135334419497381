import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';
import Section from '~/components/SectionProduct/SectionProduct';

import './kubeflow.css';

const Page = ({ data }) => {
  const {
    social,
    pages,
    title,
    sections,
    calendly,
    googleAnalytics
  } = data.site.siteMetadata;
  const kubeflow = pages.kubeflow;
  const kubeflowImages = data.kubeflow.edges;
  return (
    <Layout
      className="product"
      meta={kubeflow.metadata}
      socials={social}
      title={title}
      googleAnalytics={googleAnalytics}
      calendly={calendly}
      page="kubeflow"
    >
      <div className="kubeflow page">
        {/*<h1 className="page-title">{kubeflow.metadata.title}</h1>*/}
        <div>
          {sections.map((section, i) => (
            <Section
              title={section.title}
              subTitle={section.subTitle}
              description={section.description}
              bullets={section.bullets}
              key={i}
              image={
                kubeflowImages.filter((img) =>
                  img.node.childImageSharp.fluid.src.endsWith(section.image)
                )[0]
              }
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

Page.propTypes = {
  path: PropTypes.string,
  data: PropTypes.object
};

export default Page;

// eslint-disable-next-line one-var
export const pageQuery = graphql`
  query Page {
    kubeflow: allFile(
      filter: {
        extension: { regex: "/(jpeg|jpg|gif|png)/" }
        sourceInstanceName: { eq: "kubeflow" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        calendly {
          domain
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        googleAnalytics {
          kubeflowInquiry {
            category
            action
            label
          }
        }
        pages {
          kubeflow {
            metadata {
              title
              description
              keywords
            }
          }
        }
        sections: kubeflow {
          title
          subTitle
          description
          bullets
          image
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
  }
`;
